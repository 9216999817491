//export const API_BASE_URL = "http://localhost:8888/resetapi/api/v1";
export const API_BASE_URL = "https://slidesmap.com/resetapiqa/api/v1";
//export const API_BASE_URL = "https://api.whatisreset.com/api/v1";
export const LOGIN = API_BASE_URL + "/login";
export const USER_SIGNUP = API_BASE_URL + "/user/register";
export const FORGOT_PASSWORD = API_BASE_URL + "/user/forgotpassword";
export const UPDATE_PASSWORD = API_BASE_URL + "/user/updatepassword";
export const USER_PROFILE = API_BASE_URL + "/user/get";
export const EXPERT_PROFILE = API_BASE_URL + "/expert/get";
export const EXPERT_SIGNUP = API_BASE_URL + "/expert/register";

export const USER_UPDATE_PROFILE = "/user/update";
export const GET_THEME_LIST = "/theme/getall";
export const GET_PACKAGE_LIST = "/package/getall/";
export const GET_ASSESSMENT_LIST = "/assessment/getall/";
export const GET_ASSESSMENT_PROGRESS_LIST = "/assessment/list/";
export const START_ASSESSMENT = "/assessment/start";
export const PURCHASE_PACKAGE = "/user/purchase";
export const EXPERT_PURCHASE_PACKAGE = "/expert/package/purchase";
export const EXPERT_PACKAGE = "/expert/package/get/";
export const GET_PURCHASED_PACKAGES = "/package/purchased";
export const CHECKOUT_SESSION = "/payment/create-checkout-session";
export const GET_FIRST_ASSESSMENT = "/assessment/getcore/";
export const GET_NEXT_QUESTION = "/assessment/getNextQuestion";
export const GET_ASSESSMENT_RESULT = "/assessment/result/id/";
export const GET_ASSESSMENT_BY_ID = "/assessment/";
export const GET_ASSESSMENT_BY_NAME = "/assessment/getbyName/";
export const GET_PACKAGE_REPORT = "/user/report/";
export const GET_EXPERT_PACKAGE_LIST = "/theme/packages"
export const GET_EXPERT_USERS_LIST = "/expert/user/users"
// export const GET_EXPERT_USERS_LIST = "/user/users"
export const TICKET_CREATE = "/support/create"
export const GET_TICKET_LIST = "/support/list"
export const GET_EXPERT_TICKET_LIST = "/expert/support/list"
export const GET_EXPERT_RECENT_TICKET_LIST = "/expert/support/recentlist"
export const GET_USER_RECENT_TICKET_LIST = "/user/support/recentlist"
export const GET_SUPPORT_ID_DETAILS = "/support/"
export const SEND_MESSAGE = "/support/send"
export const UPDATE_SUPPORT_TICKET = "/support/updateStatus"
export const EXPERT_ADD_USER = "/expert/user/add"
export const EXPERT_EDIT_USER = "/expert/user/update"
export const EXPERT_DELETE_USER = "/expert/user/delete"
export const EXPERT_GET_USER_DETAILS = "/expert/user/"
export const EXPERT_UPDATE_PASSWORD = "/expert/user/changepassword"
export const EXPERT_GET_DASHBOARD_DETAILS = "/expert/dashboard"
export const EXPERT_THEME_PACKAGES = "/expert/package/purchased/";
export const EXPERT_ASSIGNED_PACKAGES = "/expert/package/assigned/";
export const EXPERT_ASSIGN_PACKAGE = "/expert/package/assign";
export const EXPERT_UNASSIGN_PACKAGE = "expert/package/unassign";
export const REPORT_DOWNLOAD = "/package/download/";
export const CHANGE_PASSWORD = "/user/changepassword";
export const EXPERT_CHECKOUT_SESSION = "/payment/expert/create-checkout-session";
export const EXPERT_REMAINING_LICENSES = "/expert/package/remaininglicenses/";
export const EXPERT_REMOVE_LICENSE = "/expert/package/removelicense";
export const EXPERT_UPDATE_PROFILE = "/expert/update";
export const USER_GET_DASHBOARD_DETAILS = "/user/dashboard"

export const USER_FAQS = API_BASE_URL + "/user/faqs";
export const EXPERT_FAQS = API_BASE_URL + "/expert/faqs";

export const GET_ASSESSMENTDETAILS_BY_ID = "/assessment/getbyId/";

export const GET_EXCHANGE_RATE = API_BASE_URL + "/user/exchagerate";

export const SAVE_CONTACT = API_BASE_URL + "/contact/save";

export const STRIPE_PUBLISHABLE_KEY = "pk_test_51MYojBSD5mJpstVXpQAwN8wSmaMIBGKtM7hRCssxp7i6He1HN7D8vwOlbgVxPXmeHIVWy3YfD5snlDlqPiocRIbF00eTvcGn8v";
export const STRIPE_SECRET_KEY = "sk_test_51MYojBSD5mJpstVX598zXVVlSr8KDtcC4GADzZ70LuGyZrmIknp33YSsUYdexO6vTeGThBteUutvaqDgYTNp14SU00oMETEFay";


export const REACT_APP_SECRET_KEY ="6LcxwGspAAAAAKwecgHu6vrQVQET-i7u4NxA3xr-";
export const REACT_APP_SITE_KEY ="6LcxwGspAAAAADTL_nliorU8aJ7Z4Stk7p5j87id";

export const GET_TOKEN = API_BASE_URL + "/getToken";

export const ACCESS_TOKEN = 'accessToken';

//export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect'
export const OAUTH2_REDIRECT_URI = 'https://slidesmap.com/oauth2/redirect'
//export const OAUTH2_REDIRECT_URI = 'https://app.whatisreset.com/oauth2/redirect'

export const LOGIN_GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?account_type=login&user_type=U&redirect_uri=' + OAUTH2_REDIRECT_URI;

export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?account_type=register&user_type=U&redirect_uri=' + OAUTH2_REDIRECT_URI;

export const EXPERT_GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?account_type=register&user_type=E&redirect_uri=' + OAUTH2_REDIRECT_URI;

export const EXPERT_SAVE_COMPANY = "/expert/saveCompany";

export const VALIDATE_USER_COUPON = "/coupon/validate/user/";
export const VALIDATE_EXPERT_COUPON = "/coupon/validate/expert/";